<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->

          <div class="row">
            <div class="col-12 mt-4">
              <label>Foto perfil / Logomarca</label>
              <div class="upload-container">
                <div
                  class="upload-preview"
                  :style="'background: url(' + avatar + ')'"
                  v-if="avatar !== '' && avatar !== null"
                >
                  <div class="remove-file" @click="avatar = ''">
                    <p>
                      <i class="fa fa-times"></i>
                    </p>
                    <p>Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label for="image" v-if="!(avatar !== '' && avatar !== null)">
                    Selecionar
                    <span class="tim-icons icon-upload"></span>
                  </label>
                  <label for="image" v-if="avatar !== '' && avatar !== null">
                    Alterar
                    <span class="tim-icons icon-pencil"></span>
                  </label>
                  <input
                    @change.prevent="addImage"
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <base-input
                :label="form.id ? 'Nome' : 'Nome *'"
                type="text"
                v-model="form.name"
              ></base-input>
            </div>

            <div class="col-6">
              <label for>{{ form.id ? 'Função' : 'Função *' }}</label>
              <select v-model="form.role" class="form-control">
                <option
                  v-for="role in roles"
                  :key="role.role"
                  :value="role.role"
                >
                  {{ role.name }}
                </option>
              </select>
            </div>

            <div class="col-6">
              <base-input
                :label="form.id ? 'Email' : 'Email *'"
                type="email"
                v-model="form.email"
              ></base-input>
            </div>

            <div class="col-6">
              <base-input
                :label="form.id ? 'Senha' : 'Senha *'"
                type="password"
                v-model="form.password"
              ></base-input>
            </div>

            <div class="col-12">
              <label for>
                {{ form.id ? 'Status/Aprovação' : 'Status/Aprovação *' }}
              </label>
              <select v-model="form.status" class="form-control">
                <option :value="0">Inativo/Excluido</option>
                <option :value="1">Ativo/Aprovado</option>
                <option :value="2">Aguardando Aprovação</option>
              </select>
            </div>
          </div>

          <div class="row mt-2" v-if="form.role === 'entidade'">
            <div class="col-12">
              <label for="entity_description">Sobre</label>
              <textarea
                id="entity_description"
                v-model="form.description"
                class="form-control"
              ></textarea>
            </div>
            <!--<div class="col-12 mt-4">
              <label>Galeria de imagens</label>
              <div class="upload-container">
                <div v-for="(image, index) in form.entity.gallery" :key="index">
                  <div
                    class="upload-preview"
                    :style="'background: url(' + image.image + ')'"
                    v-if="image.image !== ''"
                  >
                    <div class="remove-file" @click="removeGallery(index)">
                      <p>
                        <i class="fa fa-times"></i>
                      </p>
                      <p>Remover</p>
                    </div>
                  </div>
                </div>
                <div class="add-file">
                  <label for="gallery">
                    Adicionar
                    <i class="fas fa-plus"></i>
                  </label>
                  <input
                    @change.prevent="addGallery"
                    type="file"
                    id="gallery"
                  />
                </div>
              </div>
            </div>-->
          </div>

          <div class="row" v-if="form.role === 'company-master' || form.role === 'coletor' || form.role === 'entidade'">
            <div class="mt-3 divider"></div>
            <div class="col-md-12">
              <h4>
                <b>
                  {{
                    `Dados da ${
                      form.role === 'entidade' ? 'entidade' : 'empresa'
                    }`
                  }}
                </b>
              </h4>
            </div>

            <div :class="form.role === 'company-master' ? 'col-3' : 'col-6'">
              <base-input
                label="Telefone"
                type="text"
                v-model="form.user_data.phone"
              ></base-input>
            </div>

            <div :class="form.role === 'company-master' ? 'col-3' : 'col-6'">
              <base-input
                label="CPF/CNPJ"
                type="text"
                v-model="form.user_data.cnpj"
              ></base-input>
            </div>

            <div :class="form.role === 'company-master' ? 'col-3' : 'col-6'">
              <base-input
                label="Licença"
                type="text"
                v-model="form.user_data.environmental_license"
              ></base-input>
            </div>
            <div :class="form.role === 'company-master' ? 'col-3' : 'col-6'">
              <base-input
                label="Data de expiração"
                type="date"
                v-model="form.user_data.environmental_license_expiration"
              ></base-input>
            </div>

            <div class="col-6">
              <base-input
                label="Nome fantasia"
                type="text"
                v-model="form.name"
              ></base-input>
            </div>

            <div class="col-6">
              <base-input
                label="Razão social"
                type="text"
                v-model="form.user_data.company_name"
              ></base-input>
            </div>

            <div
              class="mt-3 divider"
              v-if="form.role === 'entidade' || form.data === 'company-master'"
            ></div>
            <div
              class="col-md-12"
              v-if="form.role === 'entidade' || form.data === 'company-master'"
            >
              <h4>
                <b>
                  Endereço
                  {{ form.role === 'entidade' ? 'da entidade' : 'do coletor' }}
                </b>
              </h4>
            </div>

            <div
              class="mt-3 divider"
              v-if="form.docs && form.docs.length"
            ></div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-4">
                  <base-input label="CEP" type="text" v-model="form.address.cep"></base-input>
                </div>
                <div class="col-md-4">
                  <label for>Estado</label>
                  <select
                    @change="getCities()"
                    v-model="form.address.province_id"
                    class="form-control"
                  >
                    <option
                      v-for="province in provinces"
                      :key="province.id"
                      :value="province.id"
                      >{{ province.name }}</option
                    >
                  </select>
                </div>
                <div class="col-md-4">
                  <label for>Cidade</label>
                  <select v-model="form.address.city_id" class="form-control">
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                      >{{ city.name }}</option
                    >
                  </select>
                </div>
                <div class="col-md-9">
                  <base-input
                    label="Logradouro"
                    type="text"
                    v-model="form.address.street"
                  ></base-input>
                </div>
                <div class="col-md-3">
                  <base-input
                    label="Número"
                    type="text"
                    v-model="form.address.number"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Complemento"
                    type="text"
                    v-model="form.address.complement"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Bairro"
                    type="text"
                    v-model="form.address.district"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Tipo"
                    type="text"
                    v-model="form.address.type"
                  ></base-input>
                </div>
              </div>
            </div>

            <div class="col-md-12" v-if="form.docs && form.docs.length">
              <h4>
                <b>
                  Documentos
                  {{ form.role === 'entidada' ? 'da entidade' : 'do coletor' }}
                </b>
              </h4>
            </div>

            <div class="col-12" v-if="form.docs && form.docs.length">
              <table class="table table-striped">
                <thead class="font-weight-bold bg-primary text-white">
                  <tr>
                    <td style="width: 10rem">#</td>
                    <td style="width: 15rem">Documento</td>
                    <td>Observações</td>
                    <td style="width: 15rem">Status</td>
                    <td style="width: 10rem;text-align:center">Ação</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in form.docs" :key="item.id">
                    <td style="width: 10rem">{{ index + 1 }}</td>
                    <td>{{ item.document }}</td>
                    <td>{{ item.observation }}</td>
                    <td style="width: 15rem">{{ item.approved === 0 ? 'Recusado' : item.approved === 1 ? 'Aprovado' : 'Aguardando aprovação' }}</td>
                    <doc-action
                      :item="item"
                      :beforeUpdate="getData"
                    ></doc-action>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <UserItems :form.sync="form" @update="getData"/>

          <!-- botoes -->
          <div class="row">
            <div class="col-12">
              <base-button
                class="mt-3"
                @click.prevent.stop="goBack()"
                type="danger"
                >Cancelar</base-button
              >
              <base-button class="mt-3" native-type="submit" type="success"
                >Salvar</base-button
              >
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'

export default {
  data: () => ({
    form: {
      avatar: '',
      name: '',
      entity: {
        gallery: []
      },
      gallery: [],
      user_data: {},
      address: {}
    },
    modal: false,
    cities: [],
    provinces: [],
    roles: [
      { name: 'Administrador', role: 'admin' },
      { name: 'Coletores', role: 'company-master' },
      { name: 'Entidade', role: 'entidade' },
      { name: 'Gerador', role: 'gerador' },
      { name: 'Prefeitura', role: 'prefeitura' }
    ]
  }),
  methods: {
    async getProvinces () {
      this.provinces = (await this.$http.get('provinces')).data
    },
    async getCities () {
      const id = this.form.address.province_id ? this.form.address.province_id : false
      if (id) {
        this.cities = (await this.$http.get('cities?province_id=' + id)).data
      }
    },
    addGallery (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        vue.form.entity.gallery.push({
          image: e.target.result
        })
      }
      reader.readAsDataURL(file)
    },
    removeGallery (index) {
      const gallery = this.form.entity.gallery
      const newgallery = []
      for (let i = 0; i < gallery.length; i++) {
        if (i !== index) {
          newgallery.push(gallery[i])
        }
      }
      this.form.entity.gallery = newgallery
    },
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        const data = (await this.$http.get(`admin/users/${id}`)).data
        this.form = { ...data, avatar: '' }
        await this.getProvinces()
        await this.getCities()
      } else {
        await this.getProvinces()
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`admin/users/${id}`, this.form))
              .status
          }
          if (!id) {
            status = (await this.$http.post('admin/users', this.form)).status
          }
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          await swal.fire({
            title: 'Salvo!',
            text: 'Salvo com sucesso.',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    money () {
      console.log(this.$helpers.formatMonetary(this.form.price))
    },
    addImage (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        vue.form.avatar = e.target.result
      }
      reader.readAsDataURL(file)
    },
    validate () {
      const form = this.form
      if (!form.name || form.name === '') return false
      if (!form.role || form.role === '') return false
      if (!form.email || form.email === '') return false
      if (!form.id) {
        if (!form.password || form.password === '') return false
      }
      return true
    }
  },
  computed: {
    avatar () {
      if (!this.form.avatar && !this.form.avatar_url) return ''
      if (this.form.avatar) return this.form.avatar
      return this.form.avatar_url
    }
  },
  mounted () {
    this.getData()
  },
  components: {
    DocAction: () => import('./docs-actions.vue'),
    UserItems: () => import('./user-items')
  }
}
</script>
<style lang="scss" scoped>
label {
  width: 100%;
}

.avatar-large-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
}

.avatar-large-content img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

table {
  border-radius: 0.75rem;
  overflow: hidden;
}

table thead tr {
  border-top: 2px transparent solid;
}
.upload-preview,
.add-file {
  width: 7rem !important;
  height: 7rem !important;

  label,
  p {
    font-size: 1.05rem !important;
  }
}
</style>
